import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

import './minimenu.scss'
import Nav from '../Header/navigation.js'

const tabs = [
  { url: '/', name: 'Home', tab: 'home' },
  { url: '/project', name: 'Projects', tab: 'project' },
  { url: '/blog', name: 'Blog', tab: 'blog' },
  { url: '/all', name: 'Museum', tab: 'all' }
]

class Minimenu extends React.Component {
  constructor () {
    super()
    this.state = {
      isHovered: false,
      toggleClosed: true,
      height: false,
      margin: false
    }
    this.handleHover = this.handleHover.bind(this)
    this.toggleEvent = this.toggleEvent.bind(this)
  }

  toggleEvent (e) {
    e.preventDefault()
    this.setState({ toggleClosed: !this.state.toggleClosed })
  }

  handleHover () {
    this.setState(prevState => ({
      isHovered: !prevState.isHovered
    }))
  }

  render () {
    let tabsElement = false
    let tabsDirectionLeft = false

    tabsElement = tabs.map((tab) => {
      if (tab.tab === this.props.activeTab) {
        tabsDirectionLeft = true
        return (
          <li key={`tab-${tab.tab}`} className="minimenu__tab-active">
            <Link to={tab.url}>{tab.name}</Link>
          </li>)
      } else {
        return (
          <li
            key={`tab-${tab.tab}`}
            className={`minimenu__tab-${tabsDirectionLeft ? 'left' : 'right'}`}
            onMouseEnter={this.handleHover} onMouseLeave={this.handleHover}
          >
            <Link to={tab.url}>{tab.name}</Link>
          </li>
        )
      }
    })

    return (
      <div
        ref={this.refList}
        className={`minimenu ${this.state.isHovered ? 'minimenu--hover' : ''} ${this.state.toggleClosed ? 'minimenu--closed' : ''}`}
      >
        <div className="minimenu__wrapper">
          <Nav />
        </div>
        <a href="#" onClick={this.toggleEvent} className="minimenu__toggle">menu</a>
      </div>
    )
  }
}

Minimenu.propTypes = {
  activeTab: PropTypes.string.isRequired
}

export default Minimenu
