import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

import ItemImage from '../../itemImage'
import './portfolio.scss'
import './portfolio-types.scss'

class Item extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      toggleBig: false
    }

    if (this.props.enlarge) {
      this.toggleEvent = this.toggleEvent.bind(this)
      this.listenScrollEvent = this.listenScrollEvent.bind(this)
      this.isScolling = false
      this.oneLastCheck = false
    }

    this.myRef = React.createRef()
  }

  componentDidMount () {
    window.addEventListener('scroll', this.listenScrollEvent)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.listenScrollEvent)
  }

  listenScrollEvent (e) {
    if (this.isScolling && !this.scrollRemoveTimeout) {
      const that = this
      const orgEvent = e

      this.scrollRemoveTimeout = setTimeout(() => {
        orgEvent.preventDefault()
        that.clearScrollCheckTimeout = that.scrollCheckTimeout
        clearTimeout(that.scrollCheckTimeout)
        that.clearScrollTimeoutID = that.scrollTimeout
        clearTimeout(that.scrollTimeout)
      }, 400)
    }
  }

  smoothScroll () {
    let goal = Math.round(this.myRef.current.offsetTop - 30)
    let position = Math.round(window.scrollY)
    const that = this

    if ((position !== goal)) {
      // Let's move
      this.scrollTimeout = setTimeout(() => {
        // calculate step size
        goal = Math.round(that.myRef.current.offsetTop - 30)
        position = Math.round(window.scrollY)
        let step = Math.round(0.5 * Math.abs(position - goal))
        if (step < 10) { step = 10 }
        if (Math.abs(position - goal) < 10) {
          step = Math.abs(position - goal)
        }
        if (position > goal) {
          step = -1 * step
        }
        // and now move
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
          // you're at the bottom of the page
        } else {
          window.scrollTo(0, position + step)
          that.oneLastCheck = true

          if (that.clearScrollTimeoutID !== that.scrollTimeout) {
            that.smoothScroll()
          }
        }
      }, 10)
    } else {
      // It thinks it's there
      this.scrollTimeout = setTimeout(() => {
        if (that.oneLastCheck) {
          if (that.clearScrollTimeoutID !== that.scrollTimeout) {
            that.smoothScroll()
          }
          that.oneLastCheck = false
        } else {
          that.isScolling = false
        }
      }, 50)
    }
  }

  toggleEvent (e) {
    e.preventDefault()
    this.setState({ toggleBig: !this.state.toggleBig })
    const that = this
    this.isScolling = true
    clearTimeout(this.scrollRemoveTimeout)

    this.scrollCheckTimeout = setTimeout(() => {
      if (that.clearScrollCheckTimeout !== that.scrollCheckTimeout) {
        that.smoothScroll()
      }
    }, 400)

    this.smoothScroll()
  }

  render () {
    let allData = false
    let linkElement = false
    const item = this.props.item

    const itemType = item.overviewType ? item.overviewType : 'art'

    allData = (
      <span dangerouslySetInnerHTML={{
        __html: item.shortText || item.description || item.node.excerpt
      }}/>
    )

    if (item.overviewURL || item.slug) {
      linkElement = (
        <Link className="portfolio__link portfolio__link-button" to={item.overviewURL || item.slug}>
          {item.overviewURLTextLong || 'Read the blog'}
        </Link>
      )
    } else {
      if (this.props.enlarge) {
        linkElement = (
          <a href="#toggle" className={`portfolio__link ${this.state.toggleBig ? 'portfolio__link-small' : 'portfolio__link-big'}`} onClick={this.toggleEvent}>
            {this.state.toggleBig ? 'Shrink again' : `View ${item.title}`}
          </a>
        )
      }
    }

    let imageStyle = {}
    if (item.overviewImg && item.overviewImgSize) {
      imageStyle = {
        paddingTop: item.overviewImgSize.h / item.overviewImgSize.w * 100 + '%'
      }
    }

    return (
      <div className={`portfolio portfolio--${itemType} ${this.props.enlarge ? 'portfolio--enlargeable' : ''} ${this.state.toggleBig ? 'portfolio--big' : ''}`} ref={this.myRef}>
        <div className="portfolio__image--wrapper">
          <div className="portfolio__image" style={imageStyle}>
            <ItemImage item={item}/>
          </div>
        </div>
        <div className="portfolio__title">
          <h3>{item.title}</h3>
          <p>{allData}</p>
        </div>
        {linkElement}
      </div>
    )
  }
}

Item.defaultProps = {
  item: {
    title: 'WIP',
    description: 'TBA',
    overviewImg: 'flint.png',
    overviewImgSize: { h: 3, w: 4 }
  },
  enlarge: false
}

Item.propTypes = {
  item: PropTypes.object.isRequired
}

export default Item
