import PropTypes from 'prop-types'
import React from 'react'

import './row.scss'

class Row extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      toggleClosed: false,
      hideLink: false,
      toggleLinkClasses: 'row__toggle',
      showOnlyFirstRow: props.showOnlyFirstRow
    }
    this.toggleEvent = this.toggleEvent.bind(this)
    this.resizeList = this.resizeList.bind(this)
    this.updateresizeList = this.updateresizeList.bind(this)
    this.refList = React.createRef()
  }

  resizeList () {
    clearTimeout(this.resizeTimer)
    this.resizeTimer = setTimeout(() => {
      this.updateresizeList()
      this.toggleToggleLink(false, false, false)
    }, 100)
  }

  updateresizeList () {
    if (this.refList) {
      const listElement = this.refList.current

      if (!this.state.toggleClosed) {
        this.updateShortHeigth(listElement, this.getBottomrowLength(listElement))
      } else {
        this.updateShortHeigth(listElement, this.getToprowHeight(listElement) + 5)
      }
    }
  }

  toggleToggleLink (getBottomrowLength, getToprowHeight, listElement) {
    listElement = this.refList.current
    getBottomrowLength = this.getBottomrowLength(listElement)
    getToprowHeight = this.getToprowHeight(listElement)

    if (getBottomrowLength === getToprowHeight) {
      this.setState({ hideLink: true })
    } else {
      this.setState({ hideLink: false })
    }
  }

  componentDidMount () {
    this.toggleList()
    this.toggleToggleLink(false, false, false)
    window.addEventListener('resize', this.resizeList)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.resizeList)
  }

  getToprowHeight (listElement) {
    if (!listElement.firstChild) {
      return false
    }
    let fistBottom = listElement.firstChild.getBoundingClientRect()
    fistBottom = fistBottom ? fistBottom.bottom : false
    let bottomOfFirstRow = fistBottom

    listElement.childNodes.forEach((listItemElement) => {
      const listItemBounding = listItemElement.getBoundingClientRect()
      if (fistBottom > listItemBounding.top) {
        bottomOfFirstRow = Math.max(bottomOfFirstRow, listItemBounding.bottom)
      }
    })

    if (bottomOfFirstRow) {
      return bottomOfFirstRow - listElement.getBoundingClientRect().top
    }
    return false
  }

  getBottomrowLength (listElement) {
    if (!listElement.firstChild || !listElement) {
      return false
    }
    let lastBottom = listElement.lastChild.getBoundingClientRect()
    lastBottom = lastBottom ? lastBottom.bottom : false
    let bottomOfLastRow = lastBottom

    listElement.childNodes.forEach((listItemElement) => {
      const listItemBounding = listItemElement.getBoundingClientRect()
      bottomOfLastRow = Math.max(bottomOfLastRow, listItemBounding.bottom)
    })

    return bottomOfLastRow - listElement.getBoundingClientRect().top
  }

  updateShortHeigth (listElement, listElementHeight) {
    if (this.state.showOnlyFirstRow) {
      listElement.style.height = listElementHeight + 'px'
    }
  }

  toggleEvent (e) {
    e.preventDefault()
    this.toggleList()
  }

  toggleList () {
    const listElement = this.refList.current

    if (this.state.toggleClosed) {
      this.updateShortHeigth(listElement, this.getBottomrowLength(listElement))
      this.setState({ toggleClosed: false })
    } else {
      this.updateShortHeigth(listElement, this.getToprowHeight(listElement) + 5)
      this.setState({ toggleClosed: true })
    }
  }

  render () {
    const listItems = this.props.items.map((item) =>
      <this.props.type item={item} key={`list-${encodeURI(this.props.title)}-item-${item.id}`}/>
    )

    let toggleLink = false
    if (this.state.showOnlyFirstRow) {
      let toggleLinkClasses = 'row__toggle'
      if (this.state.hideLink) { toggleLinkClasses += ' toggle--hide' }
      if (this.state.toggleClosed) { toggleLinkClasses += ' toggle--closed' }
      toggleLink =
      (<a href="#toggle" onClick={this.toggleEvent} className={toggleLinkClasses}>
        {this.state.toggleClosed ? 'Show more' : 'Show less'}
      </a>)
    }

    return (
      <div className="row">
        <h2 className="row__title">{this.props.title}</h2>
        <div className="row__list" ref={this.refList}>
          {listItems}
        </div>
        {toggleLink}
      </div>
    )
  }
}

Row.defaultProps = {
  items: PropTypes.node.isRequired,
  title: 'Default',
  showOnlyFirstRow: true
}

Row.propTypes = {
  items: PropTypes.array
}

export default Row
