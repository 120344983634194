import React from 'react'
import { Link, graphql } from 'gatsby'

import { } from '../data/projects'
import Layout from '../components/Layout/layout'
import Header from '../components/Homeheader/header'
import ProcessBlogs from '../components/datacontent'
import Bloglist from '../components/Bloglist/list'
import Row from '../components/Row/row'
import SEO from '../components/SEO/seo'
import Portfolio from '../components/Museum/Item/portfolio'

class IndexPage extends React.Component {
  constructor (props) {
    super(props)
    const { data } = this.props

    const posts = ProcessBlogs(
      data.mainItemsblogs.edges,
      data.mainItemsprojects.edges, [], true
    ).slice(0, 5)

    this.mainItems = false
    this.rowTwo = false
    if (posts.length > 2) {
      this.mainItems = posts.slice(0, 2)
      this.rowTwo = posts.slice(2)
    } else {
      this.mainItems = posts
    }

    this.museumItems = ProcessBlogs(data.museumItemsblogs.edges, data.museumItemsprojects.edges).slice(0, 3)
  }

  render () {
    return (
      <Layout activeTab="home">
        <>
          <SEO title="Welcome" keywords={['you-et', 'design', 'portfolio']} imagefile="home" location={this.props.location} />
          <Header />
          <div className="layout__content">
            <Bloglist posts={this.mainItems} size="big" />
            <Bloglist posts={this.rowTwo} size="normal" />
            <Link className="row__button" to="/project">Cases</Link>
            <Link className="row__button" to="/blog">Blog</Link>
            <Link className="row__button" to="/design-process">Design process</Link>
            <Link className="row__button" to="/project/dsfsd">Design and data</Link>
          </div>
        </>
        <>
          <div className="layout__content">
            <Row
              title="Other projects and hobbies"
              type={Portfolio}
              items={this.museumItems}
              key={'list-home-3'}
              showOnlyFirstRow={false}
            />
            <Link className="row__button" to="/all">Go to museum</Link>
          </div>
        </>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    mainItemsprojects: allProjectsJson(
      sort: { fields: [dateOrder], order: DESC },
      filter: {tags: {in: [92]}},
      limit: 10,
    ) {
      edges {
        node {
          ...ProjectNodes
        }
      }
    }
    museumItemsprojects: allProjectsJson(
      sort: { fields: [dateOrder], order: DESC },
      filter: {tags: {in: [93]}},
      limit: 6,
    ) {
      edges {
        node {
          ...ProjectNodes
        }
      }
    }

    mainItemsblogs: allMdx(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {frontmatter: {tags: {in: [92]}}},
      limit: 10,
    ) {
      edges {
        node {
          ...BlogNodes
        }
      }
    }
    museumItemsblogs: allMdx(
      sort: { fields: [frontmatter___date], order: DESC },
      filter: {frontmatter: {tags: {in: [93]}}},
      limit: 6,
    ) {
      edges {
        node {
          ...BlogNodes
        }
      }
    }
  }
`
