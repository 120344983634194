const TagData = {
  1: 'Art',
  11: 'Aquarel',
  12: 'Acrylic',
  13: 'Drawing',
  14: 'Commic',

  2: 'Design',
  21: 'Product design',
  22: 'Visual/graphic design',
  23: 'Service design',
  24: 'Sketching',
  25: 'Design method',
  26: 'Prototype',
  27: 'UX design',
  28: 'Concept',
  29: 'Illustration',

  3: 'Tech',
  31: 'AI',
  32: 'Data science',
  33: 'Website',
  34: 'Web development',

  40: 'New',
  41: 'Recent',
  42: 'Old',
  43: 'Highlight',

  51: 'CognAC',
  52: 'IO',
  53: 'Teenage',

  91: 'Cases',
  92: 'Projects',
  93: 'Designs',
  94: 'Art',

  100: 'Blog'
}

const TagDataBoosts = {
  1: 2,
  11: 2,
  12: 2,
  13: 2,
  14: 2,

  2: 2,
  21: 2,
  22: 2,
  23: 3,
  24: 3,
  25: 5,
  26: 3,
  27: 4,
  28: 4,
  29: 2,

  3: 2,
  31: 3,
  32: 3,
  33: 4,
  34: 3
}

export { TagData, TagDataBoosts }
