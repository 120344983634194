import React from 'react'
import PropTypes from 'prop-types'

import Footer from '../Footer/footer'
import Minimenu from '../Minimenu/minimenu'
import './layout.scss'

class Layout extends React.Component {
  render () {
    const activeTab = this.props.activeTab || ''
    const minimenu = this.props.hideMinimenu || <Minimenu activeTab={activeTab} />

    let layoutBottom = false
    let layoutMain = this.props.children[0]
    if (this.props.children.length === 2) {
      layoutBottom = (
        <div className="layout__bottom">
          {this.props.children[1]}
        </div>
      )
    } else {
      layoutMain = this.props.children
    }

    return (
      <div className="layout">
        <main>
          {layoutMain}
        </main>
        {layoutBottom}
        {minimenu}
        <Footer />
      </div>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
