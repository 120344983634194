import React from 'react'
import { Link } from 'gatsby'

import './header.scss'

class Header extends React.Component {
  render () {
    return (
      <>
        <header className="header-home">
          <div className="header-home__image">
            <svg viewBox="0 0 200 200">
              <g className="logo">
                <line className="st1" x1="105.81" y1="23.2" x2="61.17" y2="176.05" fill="none" stroke="#666666" strokeWidth="8" strokeMiterlimit="10"/>
                <line className="st1" x1="90.06" y1="77.15" x2="47.95" y2="43.18" fill="none" stroke="#666666" strokeWidth="8" strokeMiterlimit="10"/>
                <line className="st1" x1="76.27" y1="124.31" x2="152.37" y2="49.99" fill="none" stroke="#666666" strokeWidth="8" strokeMiterlimit="10"/>
                <line className="st1" x1="112.87" y1="88.57" x2="136.73" y2="148.41" fill="none" stroke="#666666" strokeWidth="8" strokeMiterlimit="10"/>
              </g>
            </svg>
          </div>
        </header>
        <div className="header-home__text">
          <div>
            <h1>Welcome</h1>
            <p>As a creative consultant, I like to guide clients and their projects to create value with and through Artificial Intelligence and Design. I'm developing myself into a multidisciplinary professional in the fields of AI, Software Developement and Design. This diverse background could give an innovative boost to your teams and projects!</p>
            <div className="header-home__links">
              <Link to="/project">Cases</Link>
              <Link to="/blog">Blog</Link>
              <Link to="/project/dsfsd">Design and data</Link>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Header
